<template>
	<div>
		<!-- 
			Show Form 
		-->
		<div v-if="results.length < 1">
			<!-- Street Address -->
			<ion-item>
				<div tabindex="0"></div>
				<ion-icon slot="end" v-if="streetAddressValidated === false" color="danger" :icon="close" />
				<ion-icon slot="end" v-else color="success" :icon="checkmark" />
				<ion-input v-model="streetAddress" type="text" placeholder="Street Address" autocapitalize></ion-input>
			</ion-item>
			<!-- Zip Code -->
			<ion-item>
				<div tabindex="0"></div>
				<ion-icon slot="end" v-if="zipValidated === false" color="danger" :icon="close" />
				<ion-icon slot="end" v-else color="success" :icon="checkmark" />
				<ion-input v-model="zipCode" placeholder="Zip Code" type="numeric"></ion-input>
			</ion-item>

			<!-- Actions -->
			<ion-item v-if="isFormValidated === true" lines="none">
				<div tabindex="0"></div>
				<ion-button @click.prevent="searchGooglePlaces()">Next</ion-button>
			</ion-item>
		</div>
		<!-- 
			Show Results
		-->
		<div v-else>
			<ion-item v-for="result in results.candidates" :key="result" @click.prevent="selectPlace(result)" button details>
				<ion-label style="margin-left:5px;">
					<h2>{{result.name}}</h2>
					<p>{{result.formatted_address}}</p>						
				</ion-label>
			</ion-item>
			<!-- Actions -->
			<div class="ion-text-right">
				<a href="" @click.prevent="verifyByZipCode()">Results are incorrect</a> 
				<ion-button color="light" @click.prevent="resetForm()">Reset</ion-button>
			</div>
		</div>
	</div>
</template>

<script>
import { IonItem, IonLabel, IonInput, IonButton, IonIcon } from '@ionic/vue';
import { defineComponent, ref, onMounted } from 'vue';
import { checkmark, close } from 'ionicons/icons';
import apiClient from '../services/api';

export default defineComponent( {
	components: { IonItem, IonInput, IonButton, IonIcon, IonLabel },
	props: ['placeName', 'store'],
	name: 'PlaceForm',
	setup(props) {
		const streetAddress = ref(null);
		const zipCode = ref(null);
		const results = ref([]);
		const isFormValidated = ref(false);
		const zipValidated = ref(false);
		const streetAddressValidated = ref(false);
		const showResults = ref(false);

		onMounted(() => {
			resetForm()
		})

		function validateForm() {
			validateZipCode()
			// Street Address
			if(streetAddress.value === null || streetAddress.value == '') {
				streetAddressValidated.value = false
			} else {
				streetAddressValidated.value = true
			}

			// Not validated
			if(streetAddressValidated.value === false || zipValidated.value === false) {
				isFormValidated.value = false
			} else {
				isFormValidated.value = true
			}   
		}

		function validateZipCode() {
			// Not Null
			if(zipCode.value != null) {
				// Length
				if(zipCode.value.length >= 3 && zipCode.value.length <= 5) {
					var isNum = /^\d+$/.test(zipCode.value);
					if(isNum === true) {
						zipValidated.value = true
					} else {
						zipValidated.value = false
					}       
				} else {
					zipValidated.value = false
				}
			} else {
				zipValidated.value = false
			}
		}

		function searchGooglePlaces() {
			var apiUrl = '/api/place/results/' + props.placeName + ' ' + streetAddress.value + ' ' + zipCode.value
			// Send auth token
			apiClient.get(apiUrl, 
			{
				headers: {
					'Authorization':  `Bearer ${props.store.authToken}`
				}
			}).then(response => {
				console.log(response)
				if(response.status === 200) {
					results.value = response.data
					// Could not find zip code
					if(results.value.candidates.length < 1) {
						this.verifyByZipCode()
						//showResults.value = false
					} 
				}
			}).catch(error => {
				console.log(error)  
			});
		}

		function resetForm() {
			streetAddress.value = null;
			zipCode.value = null;
			isFormValidated.value = false;
			streetAddressValidated.value = false;
			zipValidated.value = false;  
			results.value = [];
			showResults.value = false
		}

		return {
			streetAddress, zipCode, isFormValidated, results, checkmark, close,  streetAddressValidated, zipValidated, validateForm, validateZipCode, searchGooglePlaces, resetForm, showResults
		}
	},
	methods: {
		selectPlace: function(placeData) {
			var addressPieces = placeData.formatted_address.split(',');
			var trimmedStateZipString = addressPieces[2].trim();
			var stateZipArr = trimmedStateZipString.split(' ');

			var place = {
				form: 'place-form',
				formatted_address: placeData.formatted_address,
				street_address: this.streetAddress,
				country: addressPieces[3],
				zip_code: stateZipArr[1],
				state: stateZipArr[0],
				city: addressPieces[1],
				lat: placeData.geometry.location.lat,
				lng: placeData.geometry.location.lng,
				place_id: placeData.place_id,
				name: placeData.name
			}
			this.$emit('place-details', place)		
		},
		verifyByZipCode: function() {
			this.$emit('original-input', {
				zipCode: this.zipCode,
				streetAddress: this.streetAddress
			})
		}
	},
	watch: {
		streetAddress: function() {
			this.validateForm ()
		},
		zipCode: function() {
			this.validateForm ()
		}
	}
});
</script>
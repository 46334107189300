<template>
	<div>
		<div v-if="results.length > 0">
			<ion-item v-for="result in results" :key="result" @click.prevent="confirmLocation(result)">
				<ion-label style="margin-left:5px;">
					<h2>{{streetAddress}}</h2>
					<p>{{result.formatted_address}}</p>						
				</ion-label>
			</ion-item>
		</div>
		<div v-else>
			We are unable to verify any part of this address. 
		</div>
		<!-- Actions -->
		<div class="ion-text-right">
			<ion-button color="light" @click.prevent="resetForm()">Reset</ion-button>
		</div>
	</div>
</template>

<script>
import { IonItem, IonLabel, IonButton } from '@ionic/vue';
import { defineComponent, onMounted, ref } from 'vue';
import apiClient from '../services/api';

export default defineComponent({
	components: { IonItem, IonLabel, IonButton },
	props: ['zipCode', 'streetAddress', 'store'],
	name: 'VerifyByZipCode',
	setup(props) {
		const results = ref([]);
		const city = ref(null);
		const country = ref(null);
		const state = ref(null);

		onMounted(() => {
			if(props.zipCode) {
				verifyZipCode()
			}
		})

		function verifyZipCode() {
			results.value = []

			// Send auth token
			apiClient.get('/api/zip-code/results/' + props.zipCode, 
			{
				headers: {
					'Authorization':  `Bearer ${props.store.authToken}`
				}
			}).then(response => {
				console.log(response)
				if(response.status === 200) {
					results.value = response.data.results
				}
			}).catch(error => {
				console.log(error)  
			});
		}

		function confirmLocation(location) {
			if(location.address_components.length > 0) {
				for(var i=0; i<location.address_components.length; i++) {
					if(location.address_components[i].types.length > 0) {
						for(var x = 0; x<location.address_components[i].types.length; x++) {
							// City
							if( (location.address_components[i].types[x] == 'locality') || (location.address_components[i].types[x] == 'neighborhood') || (location.address_components[i].types[x] == 'sublocality_level_1') || (location.address_components[i].types[x] == 'sublocality') || (location.address_components[i].types[x] == 'postal_town') ) {
								city.value = location.address_components[i].long_name
							}
							// Country
							if(location.address_components[i].types[x] == 'country') {
								country.value = location.address_components[i].short_name
							} 
							// State
							if(location.address_components[i].types[x] == 'administrative_area_level_1') {
								state.value = location.address_components[i].short_name
							}           
						}
					}
				}
			}

			var selectedPlace = {
				form: 'zip-code-form',
				formatted_address: props.streetAddress + ', ' + location.formatted_address,
				street_address: props.streetAddress,
				country: country.value,
				zip_code: props.zipCode,
				state: state.value,
				city: city.value,
				lat: location.geometry.location.lat,
				lng: location.geometry.location.lng,
				place_id: location.place_id,				
			}
			this.dataUp(selectedPlace)
		}

		return {
			results, confirmLocation, verifyZipCode, city, country, state
		}
	},
	methods: {
		dataUp: function(selectedPlace) {
			this.$emit('place-details', selectedPlace)
		},
		resetForm: function() {
			this.$emit('reset-form')
		}
	},

});
</script>
<template>
	<div>
		<ion-item v-if="placeName != null && placeName != ''" lines="none">
			<h2>{{placeName}}</h2>					
		</ion-item>
		<!-- 
			Search Google Places 
		-->
		<div v-if="componentView == 'place-form'">
			<PlaceForm 
				:place-name="placeName" 
				@original-input="getOriginalInput" 
				@place-details="storePlace"
				:store="store" />
		</div>
		<!-- 
			Verify by Zip Code 
		-->
		<div v-else>
			<VerifyByZipCode 
				@reset-form="componentView = 'place-form'" 
				:street-address="originalStreetAddress" 
				:zip-code="originalZipCode" 
				@place-details="storePlace"
				:store="store" />			
		</div>
	</div>
</template>

<script>
import { IonItem, loadingController, toastController } from '@ionic/vue';
import { defineComponent, ref, onMounted } from 'vue';
import PlaceForm from './PlaceForm.vue'
import VerifyByZipCode from './VerifyByZipCode.vue'
import apiClient from '../services/api';

export default defineComponent( {
	components: { PlaceForm, VerifyByZipCode, IonItem },
	props: ['userId', 'placeName', 'store'],
	name: 'PlaceFormWrapper',
	setup(props) {
		const userPlaceType = ref(null);
		const placeType = ref('venue');
		const componentView = ref('place-form');
		const originalZipCode = ref(null);
		const originalStreetAddress = ref(null);
		const name = ref(null);
		const timeout = { default: 6000 }
		const place = ref(null);

		onMounted(() => {
			if(props.store.authUser.profile_type) {
				userPlaceType.value = props.store.authUser.profile_type;
			}
		})

		function getOriginalInput(e) {
			originalZipCode.value = e.zipCode;
			originalStreetAddress.value = e.streetAddress;
			componentView.value = 'verify-by-zip-code';
		}

		function storePlace(e) {
			console.log(e.country)
			presentLoading()
			// Send auth token
			if(e.form == 'place-form') {
				name.value = e.name
			} else {
				name.value = props.placeName
			}
			// If user -- make sure to get user place type
			if(props.userId > 0) {
				placeType.value = userPlaceType.value
			}

			/*console.log(
				props.userId + ', ' + name.value + ', ' + e.street_address + ', ' + e.city + ', ' + e.state + ', ' + e.zip_code + ', ' + e.country + ', ' + e.formatted_address + ', ' + e.lat + ', ' + e.lng + ', ' + e.place_id + ', ' + placeType.value + ', ' + componentView.value
			)*/

			apiClient.post('/api/store/place/', {
				user_id: props.userId,
				name: name.value,
				street_address: e.street_address,
				city: e.city,
				state: e.state,
				zip_code: e.zip_code,
				country: e.country,
				formatted_address: e.formatted_address,
				lat: e.lat,
				lng: e.lng,
				place_id: e.place_id,
				type: placeType.value,
				form_type: componentView.value
			},
			{
				headers: {
					'Authorization':  `Bearer ${props.store.authToken}`
				}
			}).then(response => {
				if(response.status === 200) {
					//open toast
					openToast()
					// If user
					if(props.userId > 0) {
						localStorage.setItem('authUser', JSON.stringify(response.data.user));
						//store.commit('setAuthUser', response.data.user);
					}
					place.value = response.data.place
				}
			}).catch(error => {
				console.log(error)  
			});			
		}

		async function presentLoading() {
			const loading = await loadingController
			.create({
				cssClass: 'my-custom-class',
				message: 'Please wait...',
				duration: timeout,
			});

			await loading.present();

			setTimeout(function() {
				loading.dismiss()
			}, timeout);
		}

		async function openToast() {
			const toast = await toastController
			.create({
				//message: '',
				duration: 6000,
				message: 'You have added a venue.',
				position: 'bottom',
				buttons: [
					{
						icon: 'close',
						role: 'cancel',
						handler: () => {

						}
					}
				]
			})
			return toast.present();
		}

		return {
			componentView, placeType, originalZipCode, originalStreetAddress, getOriginalInput, name, presentLoading, storePlace, userPlaceType, place
		}
	},
	methods: {
		dataUp: function() {
			this.$emit('place-details', this.place);
		}
	},
	watch: {
		place: {
			handler() {
				if(this.place != null) {
					this.dataUp()
				}
			},
			deep:true
		}
	}
});
</script>